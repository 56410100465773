// DO NOT IMPORT THIS MODULE DIRECTLY!!!
// The API provided by this module should be consumed via the index file.
// If you need to use the types from this module, use `import type { Type } from "@host/frame";`, but that's
// it.

import {
    ProductLaunch,
    LaunchContext,
} from "@autocorp/ava";
import {
    TriggerEmitOptions,
    InternalTriggerEmitOptions,
    ITriggerEventContext,
    ITriggerSessionContext,
    TriggerEvents,
} from "@shared/types";
import { logError, logWarning, errorHandler } from "@autocorp/ava/esm/util/error-handler";
import { IS_ERROR_INDICATOR } from "./constants";
// import { getScrollbarWidth } from "@util/window";
import { initSentry, Sentry } from "./external-api/sentry";
import { mfq, MouseflowSessionVariables } from "./external-api/mouseflow";
import { ga } from "./external-api/ga";
import {
    Analytics,
    AnalyticsEventAction,
    WidgetContextKeys,
    CtaAnalyticsContext,
} from "./external-api/analytics";

import {
    FrameHandler,
    FrameHandlerV0,
    FrameHandlerV1,
    isIframe,
} from "./handler";
import { query, initialData } from "./query";
import { mixpanelReady } from "./onload";
import { snakeCase } from "lodash";

const logVersionDifference = (message: string, frameHandler: FrameHandler, error = true) => {
    if (
        frameHandler.version !== INTERNAL_VERSION &&
        frameHandler.embed !== "static"
    ) {
        const extra = {
            query: initialData,
            sourceUrl: frameHandler.sourceUrl,
            wanted: INTERNAL_VERSION,
            actual: frameHandler.version,
        };
        const msg = `[AskAva Widget] ${message}`;
        const handler = error ? logError : logWarning;
        handler(msg, extra);
    }
    return frameHandler;
};

const getFrameHandler = () => {
    if (isIframe) {
        if (!query.version || query.version < "1.0.0") {
            return logVersionDifference(
                "Bootstrap using < v1.0.0",
                new FrameHandlerV0(),
            );
        }
        if (query.version < "2.0.0") {
            return logVersionDifference(
                "Bootstrap using < v2.0.0",
                new FrameHandlerV1(),
            );
        }
        if (query.version < INTERNAL_VERSION) {
            return logVersionDifference(
                `Bootstrap using < v${INTERNAL_VERSION}`,
                new FrameHandler(),
                false,
            );
        }
    }

    return new FrameHandler();
};


const frameHandler = getFrameHandler();
let analytics: Analytics;

export const frameMeta = {
    get embedded() {
        return !isIframe || !!frameHandler.embed;
    },
    get embedType() {
        return frameHandler.embed;
    },
    get productTargeted() {
        return !!initialData.product || frameHandler.initialPathname !== "/";
    },
};

export function triggerEvent<
    T extends boolean | undefined = undefined,
>(
    options: T extends true ? InternalTriggerEmitOptions : TriggerEmitOptions,
    internal?: T,
    appContext?: ITriggerEventContext,
    globalAppContext?: ITriggerSessionContext,
): void {
    const event = AnalyticsEventAction[
        options.event as unknown as keyof typeof AnalyticsEventAction
    ];

    if (RUNTIME_DEBUG) {
        console.log("Triggering events:", {
            options,
            AnalyticsEventAction,
            event,
        });
    }

    if (frameMeta.embedType !== "static" && !internal) {
        frameHandler.send("trigger", {
            ...options,
            event: event || options.event,
        } as unknown as TriggerEmitOptions);
    }
    mixpanelReady.then(() => {
        mixpanel.track(
            options.event,
            (options as Record<"context", any>).context,
        );
        if (appContext) mixpanel.register(appContext);
        if (globalAppContext) mixpanel.people.set(globalAppContext);
    });

    if (!event) return;

    analytics.trackWidget(
        options.event as unknown as WidgetContextKeys,
        internal ?? false,
        ("context" in options
            ? options.context
            : undefined
        ) as TriggerEvents[WidgetContextKeys],
    );
    ga.event(
        snakeCase(event),
        {
            ...("context" in options ? options.context : {}),
            source: "ava",
            medium: "iframe",
            send_to: analytics.getGa4AnalyticsId(),
        },
    );
}

let userHasEngaged = false;
export function userEngaged() {
    if (userHasEngaged) return;
    mfq.start();
    mixpanelReady.ready();

    triggerEvent({ event: "UserEngaged" }, false);

    userHasEngaged = true;
}

type PrefixKeys<T extends ObjectOf<string>, P extends string> = {
    [K in keyof T]: `${P}${Capitalize<Extract<T, string>>}`;
}

const prefixKeys = <
    P extends string,
    R extends AnyObject,
>(prefix: P, skip: Array<keyof R>, o: R) => {
    return Object.entries(o).reduce((acc, [key, val]) => {
        const prefixed = key.startsWith(prefix) || skip.includes(key)
            ? key
            : `${prefix}${key.replace(/^\S/, (m) => m.toUpperCase())}`;
        return Object.assign(acc, {
            [prefixed]: val,
        });
    }, {} as PrefixKeys<R, P>);
};

export const events = {
    ctaAnalytics: (
        type: "loaded" | "impression",
        context = {} as LaunchContext,
    ) => {
        const {
            previewSetting,
            avaProduct,
            company,
            ...ctaContext
        } = context;
        const ctx = prefixKeys("cta", ["ctaId", "clientId", "variant"], ctaContext);
        if (frameHandler.debug.analytics) {
            console.log("Frame.ctaAnalytics():", type, context);
        }

        // const gta4Id = analytics.getGa4AnalyticsId();
        // No need to send to dealer GA4 instance for this event.
        switch (type) {
            case "loaded": {
                // This tracks the CTA "loaded" event for our own GA instance
                analytics.trackCta(
                    AnalyticsEventAction.CtaLoaded,
                    ctx,
                );
                // if (gta4Id) {
                //     ga.event(AnalyticsEventAction.CtaLoaded, { ...ctx, send_to: gta4Id });
                // }

                break;
            }
            case "impression": {
                // This tracks the CTA "impression" event for our own GA instance
                analytics.trackCta(
                    AnalyticsEventAction.CtaImpression,
                    ctx,
                );
                // if (gta4Id) {
                //     ga.event(AnalyticsEventAction.CtaImpression, { ...ctx, send_to: gta4Id });
                // }

                break;
            }
        }
    },
    appOpened: ({
        product,
        context,
    } = {} as ProductLaunch) => {
        // const swidth = getScrollbarWidth(true);
        // if (swidth > 0 && !frameMeta.embedded) {
        //     document.body.classList.add("with-scrollbar");
        //     document.body.style.marginRight = `${swidth * -1}px`;
        // }

        const isNativeCta = false; // !context.ctaId && !context.product && !context.type && !context.version;
        const ctaId = isNativeCta
            ? `${initialData.widgetId}-${frameHandler.product}-nativeCta`
            : context.ctaId;

        const ctaProduct = isNativeCta
            ? frameHandler.product
            : context.product;

        const ctaType = isNativeCta
            ? "native"
            : context.type;

        const ctaVersion = isNativeCta
            ? "1.0.0"
            : context.version;

        const ctaTheme = isNativeCta
            ? "native-default"
            : context.theme || "default";

        const widgetCtaDetails: CtaAnalyticsContext = {
            ctaId,
            ctaProduct,
            ctaType,
            ctaVersion,
            ctaTheme,
        };

        analytics.startSession({
            variant: context.variant,
            ...widgetCtaDetails,
            ctaDetail: Object.values(widgetCtaDetails)
                .filter(Boolean).length
                ? `${widgetCtaDetails.ctaProduct}/${widgetCtaDetails.ctaType}/${widgetCtaDetails.ctaVersion}/${widgetCtaDetails.ctaTheme}`
                : undefined,
        });

        ga.event("app_opened", { send_to: analytics.getGa4AnalyticsId() });

        frameHandler.setProduct(product, context, true); const initialContext: Intersection<
            ITriggerSessionContext,
            MouseflowSessionVariables
        > = {
            productType: product,
            embedded: frameMeta.embedded,
            widgetId: initialData.widgetId,
            company: context.company,
            ctaId: context.ctaId,
            variant: context.variant,
            widgetVersion: NEXT_PUBLIC_GIT_COMMIT_SHA!, // WIDGET_VERSION,
        };

        const appContext: ITriggerSessionContext = {
            ...context,
            ...initialContext,
        };

        mfq.set(initialContext);

        triggerEvent(
            { event: "WidgetOpened" },
            false,
            appContext,
            appContext,
        );
    },
    appClosed: () => {
        ga.event("app_closed", { send_to: analytics.getGa4AnalyticsId() });
        ga.enableTracking(false);

        if (!frameMeta.embedded) {
            frameHandler.send("hide");
            triggerEvent({ event: "WidgetClosed" });
            mfq.stop();
            analytics.endSession();
        }

        Sentry.addBreadcrumb({
            category: "app-event",
            level: Sentry.Severity.Info,
            data: {
                event: "app-closed",
            },
        });
    },
};

let listenersSetup = false;

const setupGlobalEventListeners = () => {
    frameHandler.on("cta", events.ctaAnalytics.bind(null, "loaded"));
    frameHandler.on("ctaImpr", events.ctaAnalytics.bind(null, "impression"));
    if (!frameMeta.embedded) {
        frameHandler.on("launch", events.appOpened);
    } else {
        const product = frameHandler.selectedProduct?.product || frameHandler.product || "creditTool";
        events.appOpened({
            product,
            context: {
                ...frameHandler.selectedProduct?.context,
                ctaId: `${initialData.widgetId}-${product}-embed`,
                product: product,
                type: "embed",
                version: WIDGET_VERSION,
                theme: "embed-default",
            },
        });
    }
    listenersSetup = true;
};

const teardownGlobalEventListeners = () => {
    frameHandler.off("launch", events.appOpened);
    listenersSetup = false;
};

const initFrame = () => {
    if (!process.browser) return;

    analytics = new Analytics(frameHandler);

    initSentry();
    if (window[IS_ERROR_INDICATOR]) return;

    initSentry();

    if (!listenersSetup) setupGlobalEventListeners();
    return teardownGlobalEventListeners;
};

initFrame();

export { Sentry, frameHandler, mfq, ga, errorHandler, analytics };
export * from "@shared/frame-handler";
