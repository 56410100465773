import { h } from "preact";
import { useMemo, useEffect } from "preact/hooks";
import { useTranslation } from "react-i18next";
import useLocalStorageState from "@util/storage";

import "@style/global.scss";
import "@style/font-face.scss";
import "~/initial-setup";
import "~/i18n/config";

import { Provider, useStoreInstance } from "@store";
import { ga } from "@host";

import type {
    AppProps,
    TopLevelComponent,
    NextViewComponent,
} from "@model/view/internal";
import { frameHandler } from "@host/frame";

type NextAppProps = AppProps<TopLevelComponent<NextViewComponent, any>>;

function App(props: NextAppProps) {
    const {
        Component,
        pageProps = {},
    } = props;

    const store = useStoreInstance(pageProps.preloadedState);
    if (process.browser && RUNTIME_DEBUG) (window as any).store = store;

    // Set preferred language
    const { i18n } = useTranslation();
    useEffect(() => {
        const language = store.model.dealer.state.language;
        i18n.changeLanguage(language as unknown as string);
    }, [i18n, store.model.dealer.state.language]);

    // Setup dealer GA4 analytics tracking
    useEffect(() => {
        const ga4AnalyticsId = store.model.dealer.state.ga4AnalyticsId;

        if (!ga4AnalyticsId) {
            return;
        }

        const gtagScript = document.createElement("script");
        gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${ga4AnalyticsId}`;
        gtagScript.async = true;

        // initialize config after script is loaded
        gtagScript.onload = () => {
            ga.config(ga4AnalyticsId, {
                send_page_view: false,
                cookie_flags: "max-age=7200;secure;samesite=none",
                host_url: frameHandler.sourceUrl,
                page_url: frameHandler.sourceUrl,
                widget_version: WIDGET_VERSION,
                ...!IS_PRODUCTION && { "debug_mode": true },
            });
            ga.enableTracking(true);

        };
        document.head.appendChild(gtagScript);

        return () => {
            document.head.removeChild(gtagScript);
        };
    }, [store.model.dealer.state]);

    const RenderComponent = useMemo(() => {
        if (Component.Container) {
            return <Component.Container {...props} />;
        }

        return <Component {...pageProps} />;
    }, [Component, props, pageProps]);

    return (
        <Provider store={store}>
            {RenderComponent}
        </Provider>
    );
}

export default App;